<template>
  <div style="background: #F5F5F5">
    <van-nav-bar
        v-if="!isInMini()"
        id="nav-bar"
        title="消费明细"
        left-text="返回"
        left-arrow
        @click-left="$router.back()"
    />
    <div id="data-info" class="date-info" @click="showCalendar = true">正在查询：{{ formatDate(startDate) }} 至 {{ formatDate(endDate) }} 的订单</div>
    <van-list
        id="list"
        style="overflow: scroll"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <div class="summary">
        <div class="group-title">支出</div>
        <div style="font-size: 14px; color: #666666; margin-top: 15px">
          <span>余额：<span style="color: #333333; font-weight: bold">¥{{ (consume.wallet / 100).toFixed(2) }}</span></span>
          <span style="margin-left: 48px">积分：<span style="color: #333333; font-weight: bold">¥{{ (consume.points / 100).toFixed(2) }}</span></span>
        </div>
        <div class="cell-summary">
          <div style="display: flex; justify-content: space-between">
            <span class="cell-key">大格子(加热) x{{cell.large_heat}}</span>
            <span class="cell-value">￥{{ (cell.large_heat * 0.1).toFixed(2)}}</span>
          </div>
          <div style="display: flex; justify-content: space-between" class="margin-top-20">
            <span class="cell-key">大格子 x{{cell.large}}</span>
            <span class="cell-value">￥{{ (cell.large * 0.1).toFixed(2)}}</span>
          </div>
          <div style="display: flex; justify-content: space-between" class="margin-top-20">
            <span class="cell-key">小格子(加热) x{{cell.small_heat}}</span>
            <span class="cell-value">￥{{ (cell.small_heat * 0.1).toFixed(2)}}</span>
          </div>
          <div style="display: flex; justify-content: space-between" class="margin-top-20">
            <span class="cell-key">小格子 x{{cell.small}}</span>
            <span class="cell-value">￥{{ (cell.small * 0.1).toFixed(2)}}</span>
          </div>
        </div>
        <div class="sum">
          <span>共计：<span style="color: #BD0404; font-weight: bold">{{ consume.amount }}单</span></span>
          <span style="margin-left: 30px; margin-right: 15px">合计：<span style="color: #BD0404; font-weight: bold">¥{{ (consume.total_money / 100).toFixed(2) }}</span></span>
        </div>
      </div>
      <div class="group-title" style="margin: 15px">订单明细</div>
      <div v-for="(order, index) in orderList" :key="index" style="padding: 25px 20px 15px; background-color: white; margin-bottom: 5px">
        <div style="display: flex; justify-content: space-between">
          <div style="display: flex;">
            <div class="order-dot"></div>
            <span class="order-sn">{{order.orderSn}}</span>
          </div>
          <span class="order-sn">￥{{ (order.payMoney / 100).toFixed(2) }}</span>
        </div>
        <div style="background-color: #EFEFEF; height: 1px; margin-top: 11px; margin-left: 15px"></div>
        <div class="order-item" style="margin-top: 13px">订单类型：{{ order.orderType === 'CUSTOM' ? '其他订单' : '商城订单' }}</div>
        <div class="order-item">下单时间：{{ order.createTime.replace('T', ' ').substring(0, 19) }}</div>
        <div class="order-item" style="display: flex">
          <span style="width: 60px">保温柜：</span>
          <div>{{ order.compartmentList.map(item => { return item.lockerName + item.compartmentName + ('(' + (item.compartmentType === 'LARGE' ? '大格' : '小格') + (item.heatStatus === 'ON' ? '加热' : '') + ')') }).join('、') }}</div>
        </div>
        <div class="order-item">支付方式：{{ payType(order.payType) }}</div>
      </div>
    </van-list>
    <van-calendar v-model="showCalendar" type="range" @confirm="onConfirm" :show-confirm="false" :min-date="minDate" :max-date="new Date()"/>
  </div>
</template>

<script>
import Client from "@/api";
export default {
  name: "Consume",
  data: function () {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      pageNum: 1,
      pageSize: 20,

      orderList: [],
      startDate: new Date(),
      endDate: new Date(),
      showCalendar: false,
      consume: {},
      cell: {}
    }
  },
  mounted() {
    // document.getElementById('refresh').style.height = window.innerHeight - document.getElementById('nav-bar').clientHeight + 'px'
    let navBar = document.getElementById('nav-bar')
    let navBarHeight = navBar != null ? navBar.clientHeight : 0
    let dateInfoHeight = document.getElementById('data-info').clientHeight
    document.getElementById('list').style.height = window.innerHeight - navBarHeight - dateInfoHeight + 'px'
    this.loadConsume()
  },
  computed: {
    minDate() {
      let date = new Date()
      date.setMonth(date.getMonth() - 6)
      return date
    }
  },
  methods: {
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    payType(payType) {
      switch (payType) {
        case 'WALLET': return '余额支付'
        case 'POINTS': return '积分支付'
        case 'WEPAY': return '微信支付'
      }
    },
    onConfirm(date) {
      this.showCalendar = false
      const [start, end] = date
      this.startDate = start
      this.endDate = end
      this.onRefresh()
    },
    onLoad() {
      Client.get('courierOrder/inDateRange', {params: {pageNum: this.pageNum++, pageSize: this.pageSize, startDate: this.formatDate(this.startDate), endDate: this.formatDate(this.endDate)}}).then(res => {
        this.loading = false
        this.refreshing = false
        if (res.data.state === 'ok') {
          this.orderList.push(...res.data.list)
          if (this.pageSize > res.data.list.length) {
            this.finished = true
          }
        } else {
          this.$toast.fail(res.data.msg)
        }
      }).catch(err => {
        this.loading = false
        this.refreshing = false
        this.finished = true
        this.$toast.fail(err.response.data.msg)
      })
    },
    onRefresh() {
      this.orderList = []
      this.pageNum = 1
      this.onLoad()
      this.loadConsume()
    },
    loadConsume() {
      Client.get('courier/consumeSummery', {params: { startDate: this.formatDate(this.startDate), endDate: this.formatDate(this.endDate) }}).then(res => {
        if (res.data.state === 'ok') {
          this.consume = res.data.consume
          this.cell = res.data.cell
        }
      })
    }
  }
}
</script>

<style scoped>
.date-info {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 19px;
  height: 35px;
  background: #5C99FE;
  display: flex;
  /*justify-content: center;*/
  padding: 0 15px;
  align-items: center;
}
.summary {
  background-color: white;
  padding: 20px 15px;
}
.group-title {
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 19px;
}
.cell-summary {
  margin: 15px 0;
  padding: 15px;
  background: #EFEFEF;
  border-radius: 3px;
}
.cell-summary .cell-key {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.cell-summary .cell-value {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.margin-top-20 {
  margin-top: 20px;
}
.summary .sum {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  display: flex;
  justify-content: flex-end;
}
.order-dot {
  width: 5px;
  height: 20px;
  background: #FFBD04;
}
.order-sn {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 20px;
  margin-left: 10px;
}
.order-item {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-top: 15px;
  margin-left: 15px;
}
</style>
